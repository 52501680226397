import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import LicenseListPage from './pages/LicenseListPage';
import LicenseFormPage from './pages/LicenseFormPage';
import LicenseRecordListPage from './pages/LicenseRecordListPage';
import LicenseRecordFormPage from './pages/LicenseRecordFormPage';
import LandingPage from './pages/LandingPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import UnauthorizedPage from './pages/UnauthorizedPage';
import DashboardPage from './pages/DashboardPage';
import EconomicCalendarPage from './pages/EconomicCalendarPage';
import EconomicCalendarDetailPage from './pages/EconomicCalendarDetailPage';
import RiskSettingsPage from './pages/RiskSettingsPage';
import FeatureListPage from './pages/FeatureListPage';
import FeatureFormPage from './pages/FeatureFormPage';
import ApplicationListPage from './pages/ApplicationListPage';
import ApplicationFormPage from './pages/ApplicationFormPage';
import SubscriptionListPage from './pages/SubscriptionListPage';
import SubscriptionFormPage from './pages/SubscriptionFormPage';
import UserListPage from './pages/UserListPage';
import UserFormPage from './pages/UserFormPage';
import AccountPage from './pages/AccountPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import MyLicensesPage from './pages/MyLicensesPage';
import ErrorBoundary from './components/ErrorBoundary';
import ActivateUserPage from './pages/ActivateUserPage';
import TermsPage from './pages/TermsPage';
import PammPage from './pages/PammPage';
import ProfitPulsePage from './pages/Products/ProfitPulse';
import InfoLinkPage from './pages/InfolinkPage';

const App = () => {
  return (
    <ErrorBoundary>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/pamm" element={<PammPage />} />
        <Route path="/product/profitpulse" element={<ProfitPulsePage />} />
        <Route path="/infolink" element={<InfoLinkPage />} />
        {/* <Route path="/" element={<LoginPage />} /> */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/unauthorized" element={<UnauthorizedPage />} />       
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/activate-user" element={<ActivateUserPage />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute allowedRoles={['sys-admin']} />}>
          <Route path='/calendar' element={<EconomicCalendarPage />} />
          <Route path="/calendar/detail/:date" element={<EconomicCalendarDetailPage />} />
          <Route path="/licenses" element={<LicenseListPage />} />
          <Route path="/licenses/create" element={<LicenseFormPage />} />
          <Route path="/licenses/edit/:id" element={<LicenseFormPage />} />
          <Route path="/risksettings" element={<RiskSettingsPage />} />
          <Route path="/risksettings/edit" element={<RiskSettingsPage />} />
          <Route path="/features" element={<FeatureListPage />} />
          <Route path="/features/create" element={<FeatureFormPage />} />
          <Route path="/features/edit/:id" element={<FeatureFormPage />} />
        </Route>
        <Route element={<PrivateRoute allowedRoles={['sys-admin', 'admin']} />}>                    
          <Route path="/license-records" element={<LicenseRecordListPage />} />
          <Route path="/license-records/create" element={<LicenseRecordFormPage />} />
          <Route path="/license-records/edit/:id" element={<LicenseRecordFormPage />} /> 
          <Route path="/applications" element={<ApplicationListPage />} />
          <Route path="/applications/create" element={<ApplicationFormPage />} />
          <Route path="/applications/edit/:id" element={<ApplicationFormPage />} />
          <Route path="/subscriptions" element={<SubscriptionListPage />} />
          <Route path="/subscriptions/create" element={<SubscriptionFormPage />} />
          <Route path="/subscriptions/edit/:id" element={<SubscriptionFormPage />} />
          <Route path="/users" element={<UserListPage />} />
          <Route path="/users/create" element={<UserFormPage />} />
          <Route path="/users/edit/:id" element={<UserFormPage />} />
        </Route>    
        <Route element={<PrivateRoute allowedRoles={[ 'sys-admin','admin','customer' ]} />}>
          <Route path='/dashboard' element={<DashboardPage />} />
          <Route path="/account" element={<AccountPage />} />
          <Route path="/change-password" element={<ChangePasswordPage />} />
          <Route path='/my-licenses' element={<MyLicensesPage/>} />
        </Route>       
      </Routes>
    </Router>
    </ErrorBoundary>
  );
};

export default App