const config = {
    appTitle: "FINTECH STRATEGIES",
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
    sysAdminCredentials: {
      username: process.env.REACT_APP_SYS_ADMIN_USERNAME,
      password: process.env.REACT_APP_SYS_ADMIN_PASSWORD,
    },
    recaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY,
    themeColor: 'black', // (blue) Default color, white, black, grey, red, green, 
  };
  
  export default config;
  