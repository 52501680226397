import React from 'react';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';

const InfoLinkPage = () => {
  return (
    <>
      <div className="footer-bg py-10 text-white">
        <div className="container mx-auto px-4" style={{ marginTop: '-240px' }}>
          <div className="flex items-center justify-center pb-16">
            <div className="rounded-3xl footer-symbol overflow-hidden p-8 sm:p-12 lg:p-16 text-center text-white shadow-lg relative">
              <div className="relative z-10">
                <div className="mt-8 flex flex-col sm:flex-row justify-center gap-4">
                  <a
                    href="/dashboard"
                    className="rounded-full bg-[#87A3A8] px-6 py-3 flex justify-center items-center text-sm font-medium text-black shadow-md transition hover:bg-opacity-80"
                  >
                    Investor Dashboard
                  </a>
                  <a
                    href="#contact-section"
                    className="px-6 py-3 rounded-full border border-gray-400 text-sm sm:text-base font-semibold hover:bg-gray-700"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4">
          <div className="flex flex-col items-center justify-between">
            <div className="flex flex-col items-center md:items-start">
              <img src="/assets/logo.png" alt="logo" className="w-32" />
            </div>
            <nav className="mt-6 flex flex-wrap justify-center gap-4 md:mt-0">
              <a href="/" className="text-white hover:text-gray-200">Home</a>
              <a href="/#hero-section" className="text-white hover:text-gray-200">About us</a>
              <a href="/#sec-overview" className="text-white hover:text-gray-200">Benefits</a>
              <a href="/#feature-section" className="text-white hover:text-gray-200">Features</a>
              <a href="/#products-section" className="text-white hover:text-gray-200">Products</a>
              <a href="https://myfxbooks.com/" target="blank" className="text-white hover:text-gray-200">Performance</a>
              <a href="/pamm" className="text-white hover:text-gray-200">PAMM</a>
              <a href="/#contact-section" className="text-white hover:text-gray-200">Contact</a>
            </nav>
          </div>

          {/* Embedded MP4 video from Google Drive */}
          <div className="mt-6 flex justify-center">
            <iframe
              src="https://fintechstrategies.blob.core.windows.net/public/ProfitPulse-EA-Presentation.mp4"
              width="640"
              height="360"
              allow="autoplay"
              className="rounded-lg shadow-lg"
            ></iframe>
          </div>

          {/* PDF link button */}
          <div className="mt-6 flex justify-center">
            <a
              href="/assets/ProfitPulseEASlidesRev2.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="rounded-full bg-blue-500 px-6 py-3 text-white text-sm font-medium shadow-md transition hover:bg-blue-600"
            >
              View PDF
            </a>
          </div>

          <div className="flex justify-center items-center gap-4 mt-5">
            <a href="https://instagram.com/fintech_strategies" target="_blank" rel="noreferrer">
              <InstagramIcon className="text-white cursor-pointer hover:text-gray-400" />
            </a>
            <span className="bg-white w-[1px] h-[30px]"></span>
            <a href="https://facebook.com/fintechstrategiesllc" target="_blank" rel="noreferrer">
              <FacebookIcon className="text-white cursor-pointer hover:text-gray-400" />
            </a>
          </div>

          <div className="mt-5 text-center text-sm text-gray-400">
            <p>&copy;2025 Fintech Strategies, LLC. All Rights Reserved.</p>
            <p className="mt-4">
              All rights, title, and interest in PROFITPULSE EA, including algorithms, software and related technology, remain with Fintech Strategies, LLC. 
              PROFITPULSE EA is provided "AS IS” without any warranties, express or implied. User acknowledges the inherent risks of using software for trading, 
              including potential loss of investment. Past performance does not guarantee future results...
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoLinkPage;
